<template>
  <div>
    <NavTitle class="mb16" title="商务名片" :showBack="false"></NavTitle>
    <div class="panel" v-loading="loading">
      <PanelTitle style="padding: 0;" title="名片信息"/>
      <el-form label-width="100px" label-position="left">
        <el-form-item label="头像：">
          <UploadImage :autoUpload="false" :showUploadImg="!form.avatar.img" @success="avatarUploadSuccess">
            <template v-if="form.avatar.img">
              <div class="avatar">
                <LoadImage class="img" :src="form.avatar.img"></LoadImage>
                <div class="label">编辑头像</div>
              </div>
            </template>
          </UploadImage>
        </el-form-item>
        <el-form-item label="姓名：">
          <div class="flex between-center">
            <div v-if="!nameEdit">{{form.consultantName}}</div>
            <el-input v-else style="width: 200px;" placeholder="请输入姓名" v-model="form.consultantName"></el-input>
            <div class="btn-edit" @click="nameEdit = !nameEdit">
              <img class="icon-edit" src="@/assets/images/icon-edit-line3.png">
              <span class="theme-text">{{nameEdit ? '取消':'编辑'}}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="联系方式：">
          <div class="flex between-center">
            <div v-if="!contactEdit">{{form.contactInfo}}</div>
            <el-input v-else style="width: 200px;" placeholder="请输入联系方式" v-model="form.contactInfo"></el-input>
            <div class="btn-edit" @click="contactEdit = !contactEdit">
              <img class="icon-edit" src="@/assets/images/icon-edit-line3.png">
              <span class="theme-text">{{contactEdit ? '取消':'编辑'}}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="名片：">
          <UploadImage :autoUpload="false" :imgUrl="form.contactImg.img" @success="cardUploadSuccess"></UploadImage>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-end">
            <el-button type="primary" @click="handleSave">保存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import UploadImage from '@/components/UploadImage'
import LoadImage from '@/components/LoadImage'
import { consultantManageApi } from '@/api/admin.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
export default {
  components: {
    NavTitle,
    PanelTitle,
    UploadImage,
    LoadImage
  },
  data() {
    return {
      form: {
        avatar: {
          img: '',
          file: null
        },
        consultantName: '',
        contactInfo: '',
        contactImg: {
          img: '',
          file: null
        }
      },
      nameEdit: false,
      contactEdit: false,
      loading: false
    }
  },
  created() {
    this.getConsulantData()
  },
  methods: {
    getConsulantData() {
      this.loading = true
      consultantManageApi({
        act: 3
      }).then(res => {
        console.log('查询当前商务名片信息', res)
        this.loading = false
        const selfConsultantInfo = res.data.selfConsultantInfo
        if(selfConsultantInfo) {
          this.form.consultantName = selfConsultantInfo.consultantName || ''
          this.form.avatar.img = selfConsultantInfo.consultantPortrait || ''
          this.form.contactInfo = selfConsultantInfo.contactInfo || ''
          this.form.contactImg.img = selfConsultantInfo.contactCardImg || ''
        }
        if(!this.form.consultantName) this.nameEdit = true
        if(!this.form.contactInfo) this.contactEdit = true
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type: 'error'})
      })
    },
    avatarUploadSuccess(e) {
      console.log('e',e)
      this.form.avatar.img = e.base64Url
      this.form.avatar.file = e.file
    },
    cardUploadSuccess(e) {
      console.log('e',e)
      this.form.contactImg.img = e.base64Url
      this.form.contactImg.file = e.file
    },
    async handleSave() {
      if(!this.form.consultantName) return this.$tips({message: '请填写姓名', type: 'warning'})
      if(!this.form.contactInfo) return this.$tips({message: '请填写联系方式', type: 'warning'})
      if(!this.form.avatar.img && !this.form.avatar.file) return this.$tips({message: '请上传个人头像', type: 'warning'})
      if(!this.form.contactImg.img && !this.form.contactImg.file) return this.$tips({message: '请上传名片', type: 'warning'})
      const formData = {
        act: 1,
        consultantInfo: {
          consultantName: this.form.consultantName,
          contactInfo: this.form.contactInfo,
          consultantPortrait: this.form.avatar.img,
          contactCardImg: this.form.contactImg.img
        }
      }
      try {
        const { uploadImageAsync } = await uploadImageUtil()
        if(this.form.avatar.file) {
          const res = await uploadImageAsync(this.form.avatar.file)
          formData.consultantInfo.consultantPortrait = res.ossUrl
        }
        if(this.form.contactImg.file) {
          const res = await uploadImageAsync(this.form.contactImg.file)
          formData.consultantInfo.contactCardImg = res.ossUrl
        }
      } catch (err) {
        this.$tips({message: '上传图片失败', type: 'error'})
        return
      }
      console.log('formData', formData)
      this.loading = true
      consultantManageApi(formData).then(res => {
        console.log('上传名片信息')
        this.loading = false
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding:  0 24px 24px;
  margin: 16px 20px 0;
}
.avatar {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 39px;
  overflow: hidden;
  .img {
    width: 100%;
    height: 100%;
  }
  .label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 20px;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    font-size: 10px;
    text-align: center;
  }
}
.btn-edit {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  .icon-edit {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
</style>